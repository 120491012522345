import React from 'react';
import { Button, Checkbox, Divider, ExpansionList, ExpansionPanel } from 'react-md';
import { titleCase } from '../utils/TitleCase';

const OrgFilterItem = ({ data, updateFilter }:{data:any, updateFilter:any}) => {
  const setChecked = ([label, value]:[string, any]) => {
    value = !value;
    updateFilter(label, !value);
  };

  return (
    <div>
      <ExpansionPanel label="Status" footer={null} defaultExpanded className="FilterPanel">
        {Object.entries(data)
          .filter(([label]) => label === 'active' || label === 'inactive')
          .map(([label, value]) =>
            (
              <Checkbox
                controlClassName="CheckBox"
                id={`checkbox-${label}`}
                name="active-checkboxes[]"
                label={titleCase(label)}
                checked={!!value}
                onChange={() => {
                  setChecked([label, value]);
                }}
              />
            ))}
      </ExpansionPanel>
      <ExpansionPanel label="Plan" footer={null} defaultExpanded className="FilterPanel">
        {Object.entries(data)
          .filter(([label]) => label !== 'active' && label !== 'inactive')
          .map(([label, value]) => (
            <Checkbox
              controlClassName="CheckBox"
              id={`checkbox-${label}`}
              name="active-checkboxes[]"
              label={titleCase(label)}
              checked={!!value}
              onChange={() => { setChecked([label, value]); }}
            />
          ))}
      </ExpansionPanel>
    </div>
  );
};

const OrgFilterItems = ({ data, parentCallback }:{data:any, parentCallback:any}) => {
  const clearAll = () => {
    parentCallback();
  };
  return (
    <div style={{ height: 200, display: 'flex', overflowY: 'auto' }}>
      <h2 style={{ position: 'absolute', left: 15, paddingTop: 15, borderBottom: 1 }}>Filter </h2>
      <Button
        style={{ position: 'absolute', right: 10 }}
        primary
        onClick={() => clearAll()}
        flat
      >
                        Clear All
      </Button>
      <Divider inset style={{ borderBottom: 1 }} />
      <ExpansionList style={{ marginTop: 65, position: 'absolute', left: 10, width: 460, height: '75%', overflowY: 'auto' }}>
        <OrgFilterItem data={data} updateFilter={(key:string, value: boolean) => parentCallback(key, !value)} />
      </ExpansionList>
    </div>
  );
};
export default OrgFilterItems;
