import React from 'react';
import { connect } from 'react-redux';
import { oauthCallback } from '../store/auth/auth-actions';
import { PageSpinner } from '../components/utils/PageSpinner';

interface AuthCallbackProps {
  oauthCallback: typeof oauthCallback;
}

export class AuthCallbackPage extends React.Component<AuthCallbackProps> {
  componentDidMount() {
    this.props.oauthCallback();
  }

  render() {
    return <PageSpinner />;
  }
}

const mapDispatchToProps = {
  oauthCallback,
};

export default connect(
  null,
  mapDispatchToProps,
)(AuthCallbackPage);
