import React from 'react';
import { AccessibleFakeButton, Avatar, DropdownMenu, FontIcon, IconSeparator } from 'react-md';
import { connect } from 'react-redux';
import { IUser } from '@mmc-csm/shared';
import { ApplicationState } from '../../store';
import { signOut } from '../../store/auth/auth-actions';

interface SignedUserDropDownProps {
  user?: IUser;
  onSignOut: typeof signOut;
}

export const SignedUserDropDown: React.FC<SignedUserDropDownProps> = ({ user, onSignOut }) => {
  const fullName = (user && user.name) || 'User';
  const pictureUrl = (user && user.picture) || '';
  let firstName = fullName;

  if (fullName.indexOf(' ') > -1) {
    firstName = fullName.substr(0, fullName.indexOf(' '));
  }

  const label = (
    <IconSeparator label={firstName} className="SignedUserDropDownUserName">
      <FontIcon>arrow_drop_down</FontIcon>
    </IconSeparator>
  );

  return (
    <div className="md-btn--toolbar">
      <DropdownMenu
        id="signed-user-dropdown"
        menuItems={{ primaryText: 'Sign out', onClick: onSignOut }}
        simplifiedMenu={false}
        anchor={{
          x: DropdownMenu.HorizontalAnchors.INNER_RIGHT,
          y: DropdownMenu.VerticalAnchors.BOTTOM,
        }}
      >
        <AccessibleFakeButton component={IconSeparator} iconBefore label={label}>
          <Avatar suffix="pink" alt={fullName} src={pictureUrl} />
        </AccessibleFakeButton>
      </DropdownMenu>
    </div>
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  user: auth.user,
});

const mapDispatchToProps = {
  onSignOut: signOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignedUserDropDown);
