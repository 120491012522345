import React from 'react';
import { Button } from 'react-md';
import { useHistory } from 'react-router';

export const NotFoundPage: React.FC = () => {
  const history = useHistory();

  return (
    <div className="NotFoundPage">
      <div>
        <h1>Oops!</h1>
        <h2>PAGE NOT FOUND</h2>
        <Button primary raised onClick={() => history.push('/')}>
          Go to Home
        </Button>
      </div>
    </div>
  );
};
