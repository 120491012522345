import React, { useState } from 'react';
import { Button, Chip, Drawer, FontIcon, Toolbar } from 'react-md';
import OrgFilterItems from './OrgFilterItems';
import { titleCase } from '../utils/TitleCase';

const FILTERS_OFF = {
  active: false,
  inactive: false,
  free: false,
  freeExpired: false,
  starter: false,
  professional: false,
  enterprise: false,
  monthly: false,
  annualSmall: false,
  annualMedium: false,
  annualLarge: false,
};
const FilterChips = ({ label }:{label:string}) => (
  <Chip label={titleCase(label)} className="FilterChips" />
);

interface OrgsFilterProps {
  onFilter: (filter: object) => void;
}

export const OrgFilters: React.FC<OrgsFilterProps> = ({ onFilter }) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(FILTERS_OFF);


  const chipsCallback = (key:string, value:boolean) => {
    if (key) {
      const updatedValue = { [key]: value };
      const tempData = { ...data, ...updatedValue };
      setData(tempData);
      onFilter(tempData);
    } else {
      setData(FILTERS_OFF);
      onFilter(FILTERS_OFF);
    }
  };

  const openDrawer = () => {
    if (open) setVisible(false);
    else setVisible(true);

    setOpen(!open);
  };

  const handleVisibility = () => {
    setVisible(false);
    setOpen(false);
  };


  const drawerComp = <OrgFilterItems data={data} parentCallback={chipsCallback} />;

  return (
    <div>
      <div className="FilterButton">
        <Button iconChildren={<FontIcon>filter_list</FontIcon>} primary flat swapTheming={open} onClick={() => openDrawer()}>
         Filter
        </Button>

        <Drawer
          classname="OrgDrawer"
          type={Drawer.DrawerTypes.TEMPORARY}
          visible={visible}
          position="right"
          onVisibilityChange={() => handleVisibility()}
          header={(
            <Toolbar
              actions={drawerComp}
              className="OrgDrawer"
            />
          )}
          style={{ paddingTop: 65, width: 480 }}
        />
      </div>
      {Object.entries(data)
        .filter(([, value]) => value)
        .map(([label]) => <FilterChips label={label} />)}
    </div>
  );
};

export default OrgFilters;
