import { Reducer } from 'redux';
import { ToastsActionTypes, ToastsState } from './toasts-types';

export const toastsInitialState: ToastsState = {
  toasts: [],
};

export const toastsReducer: Reducer<ToastsState> = (state = toastsInitialState, action) => {
  switch (action.type) {
    case ToastsActionTypes.ADD_TOAST:
      return { ...state, toasts: [...state.toasts, action.payload] };

    case ToastsActionTypes.REMOVE_TOAST:
      return { ...state, toasts: state.toasts.slice(1) };

    default:
      return state;
  }
};
