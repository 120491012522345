import { Reducer } from 'redux';
import { IOSAppVersionActionTypes, IOSAppVersionState } from './ios-app-version-types';

export const iosAppVersionInitialState: IOSAppVersionState = {
  dialogVisible: false,
  loading: false,
  saving: false,
};

export const iosAppVersionReducer: Reducer<IOSAppVersionState> = (state = iosAppVersionInitialState, action) => {
  switch (action.type) {
    case IOSAppVersionActionTypes.GET_VERSION: {
      return { ...state, loading: true };
    }
    case IOSAppVersionActionTypes.GET_VERSION_SUCCESS: {
      const { version } = action.payload;
      return { ...state, version, loading: false, editedVersion: version };
    }
    case IOSAppVersionActionTypes.GET_VERSION_ERROR: {
      return { ...state, version: 'Error', loading: false, editedVersion: '' };
    }
    case IOSAppVersionActionTypes.CHANGE_VERSION: {
      return { ...state, dialogVisible: true };
    }
    case IOSAppVersionActionTypes.EDIT_VERSION: {
      return { ...state, editedVersion: action.payload.version };
    }
    case IOSAppVersionActionTypes.SAVE_VERSION: {
      return { ...state, saving: true, dialogVisible: false };
    }
    case IOSAppVersionActionTypes.SAVE_VERSION_SUCCESS: {
      const { version } = action.payload;
      return { ...state, saving: false, version, editedVersion: version };
    }
    case IOSAppVersionActionTypes.SAVE_VERSION_ERROR: {
      return { ...state, saving: false, editedVersion: state.version };
    }
    case IOSAppVersionActionTypes.CLOSE_DIALOG: {
      return { ...state, dialogVisible: false };
    }
    default: {
      return state;
    }
  }
};
