import React, { useState } from 'react';
import { Button, FontIcon, TextField } from 'react-md';

interface OrgsSearchProps {
  defaultValue?: string;
  onSearch: (search: string) => void;
}
const ButtonIcon = ({ buttonIconValue = false }) => <FontIcon>{buttonIconValue ? 'close' : 'search' }</FontIcon>;

export const OrgsSearchBar: React.FC<OrgsSearchProps> = props => {
  const [buttonIconValue, setbuttonIconValue] = useState(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      props.onSearch((e.currentTarget as HTMLInputElement).value);
      setbuttonIconValue(true);
    }
  };

  const handleClick = () => {
    const fieldInput = document.getElementById('orgs-search') as HTMLInputElement | null;

    if (!buttonIconValue && fieldInput) {
      props.onSearch(fieldInput.value);
      setbuttonIconValue(true);
    } else {
      (document.getElementById('orgs-search') as HTMLInputElement).value = '';
      props.onSearch('');
      setbuttonIconValue(false);
    }
  };

  return (
    <div className="OrgsSearchBar">
      <div className="OrgsSearchInput">
        <TextField
          id="orgs-search"
          defaultValue={props.defaultValue}
          inlineIndicator={<Button onClick={handleClick}><ButtonIcon buttonIconValue={buttonIconValue} /></Button>}
          onKeyDown={handleKeyDown}
          fullWidth
          placeholder="Search by Name or Email"
        />
      </div>
    </div>
  );
};
