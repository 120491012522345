import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, DialogContainer, FontIcon, TextField } from 'react-md';
import { TextFieldComponent } from 'react-md/lib/TextFields/TextField';
import { AdminToolsItem } from '../tools/AdminToolsItem';
import { ApplicationState } from '../../store';
import { SkeletonKeysState } from '../../store/skeleton-keys/skeleton-keys-types';
import { closeDialog, generateKey } from '../../store/skeleton-keys/skeleton-keys-actions';
import { InfoMessage } from '../utils/InfoMessage';
import { addToast } from '../../store/toasts/toasts-actions';

type SkeletonKeysProps = SkeletonKeysState & {
  generateKey: typeof generateKey;
  closeDialog: typeof closeDialog;
  addToast: typeof addToast;
};

export const SkeletonKeys: React.FC<SkeletonKeysProps> = props => {
  const textField = useRef<TextFieldComponent>();

  const copyToClipboard = (e: any) => {
    if (!textField.current) {
      return;
    }
    // We know that the input does have select method
    // @ts-ignore
    textField.current.getField()!.select();
    document.execCommand('copy');
    e.currentTarget.focus();
    props.addToast({ text: 'Copied!' });
  };

  const actions = [];
  actions.push({ children: 'Close', onClick: props.closeDialog });

  if (!props.generating) {
    actions.push({ children: 'Copy to Clipboard', onClick: copyToClipboard, primary: true });
  }

  return (
    <>
      <AdminToolsItem
        icon={<FontIcon>vpn_key</FontIcon>}
        title="Skeleton keys"
        description="A one time password that allows to access any organization account."
        buttonText="Generate a key"
        onButtonClick={props.generateKey}
      />
      <DialogContainer
        id="skeleton-keys-dialog"
        visible={props.dialogVisible}
        onHide={props.closeDialog}
        actions={actions}
        focusOnMount={false}
        width={400}
        title="New skeleton key"
      >
        {props.generating && <CircularProgress id="skeleton-key-prg" />}
        {!props.generating && (
          <>
            <InfoMessage>The key will last for 15 minutes.</InfoMessage>
            <TextField
              id="txt-skeleton-key"
              ref={field => {
                textField.current = field as unknown as TextFieldComponent;
              }}
              inlineIndicator={<FontIcon>vpn_key</FontIcon>}
              value={props.skeletonKey}
            />
          </>
        )}
      </DialogContainer>
    </>
  );
};

const mapStateToProps = ({ skeletonKeys }: ApplicationState) => ({
  dialogVisible: skeletonKeys.dialogVisible,
  skeletonKey: skeletonKeys.skeletonKey,
  generating: skeletonKeys.generating,
});

const mapDispatchToProps = {
  generateKey,
  closeDialog,
  addToast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkeletonKeys);
