import React from 'react';
import { Cell, CircularProgress, Grid } from 'react-md';

export const PageSpinner = () => (
  <Grid className="PageSpinner">
    <Cell size={12} align="middle">
      <CircularProgress id="loader" scale={3} />
    </Cell>
  </Grid>
);
