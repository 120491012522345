import React from 'react';
import { CircularProgress } from 'react-md';

interface DataTableSpinnerProps {
  id: string;
  loading: boolean;
}

export const DataTableSpinner: React.FC<DataTableSpinnerProps> = ({ id, loading, children }) => (
  <div className="DataTableSpinner">
    {loading && (
      <div className="DataTableSpinnerOverlay">
        <CircularProgress id={id} scale={2} />
      </div>
    )}
    {children}
  </div>
);
