export enum SkeletonKeysActionTypes {
  GENERATE_KEY = '@@skeys/LIST',
  GENERATE_KEY_SUCCESS = '@@skeys/GENERATE_KEY_SUCCESS',
  GENERATE_KEY_ERROR = '@@skeys/GENERATE_KEY_ERROR',
  CLOSE_DIALOG = '@@skeys/CLOSE_DIALOG',
}

export interface SkeletonKeysState {
  readonly dialogVisible: boolean;
  readonly generating: boolean;
  readonly skeletonKey?: string;
}
