export enum IOSAppVersionActionTypes {
  GET_VERSION = '@@iosappversion/GET_VERSION',
  GET_VERSION_SUCCESS = '@@iosappversion/GET_VERSION_SUCCESS',
  GET_VERSION_ERROR = '@@iosappversion/GET_VERSION_ERROR',

  CHANGE_VERSION = '@@iosappversion/CHANGE_VERSION',
  EDIT_VERSION = '@@iosappversion/EDIT_VERSION',

  SAVE_VERSION = '@@iosappversion/SAVE_VERSION',
  SAVE_VERSION_SUCCESS = '@@iosappversion/SAVE_VERSION_SUCCESS',
  SAVE_VERSION_ERROR = '@@iosappversion/SAVE_VERSION_ERROR',

  CLOSE_DIALOG = '@@iosappversion/CLOSE_DIALOG',
}

export interface IOSAppVersionState {
  readonly dialogVisible: boolean;
  readonly loading: boolean;
  readonly saving: boolean;
  readonly version?: string;
  readonly editedVersion?: string;
}
