import React from 'react';
import { DialogContainer } from 'react-md';

interface ConfirmationDialogProps {
  title: string;
  message: string;
  confirmButtonText?: string;
  visible: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
  const { title, message, confirmButtonText, visible, onConfirm, onCancel } = props;

  const actions = [
    { children: 'Cancel', onClick: onCancel },
    { children: confirmButtonText || 'Confirm', primary: true, onClick: onConfirm },
  ];

  return (
    <DialogContainer
      id="confirmation-dialog"
      visible={visible}
      actions={actions}
      width={400}
      title={title}
      onHide={onCancel}
    >
      <span>{message}</span>
    </DialogContainer>
  );
};
