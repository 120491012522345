import React, { memo, ReactText, useCallback, useState } from 'react';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields/TextField';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';

interface Props {
  readonly changingSeats: boolean;
  readonly id: string;
  readonly onChangeSeats: (newSeats: number) => void,
  readonly seats: number;
  readonly activeUsers: number;
}

const OrgSeatsField = memo<Props>(({ changingSeats, id, onChangeSeats, seats, activeUsers }) => {
  const [licenses, setLicenses] = useState<ReactText>(seats);
  const [error, setError] = useState<string | undefined>();
  const handleChangeLicenses = useCallback(
    (value: ReactText) => {
      const parsedValue = parseInt(`${value}`.trim(), 10);
      if (!Number.isNaN(parsedValue) && parsedValue > 0) {
        setError(undefined);
      } else if (Number.isNaN(parsedValue) && `${value}`.trim() !== '') {
        setError('Please enter a number');
      } else if (parsedValue <= 0) {
        setError('Number of seats must be positive');
      }
      setLicenses(Number.isNaN(parsedValue) ? '' : parsedValue);
    },
    [setError, setLicenses],
  );
  const numericLicenses = parseInt(`${licenses}`.trim(), 10);
  const isValidNumber = Number.isInteger(numericLicenses) && numericLicenses > 0;
  const handleSaveClick = useCallback(() => onChangeSeats(numericLicenses), [numericLicenses, onChangeSeats]);

  return (
    <div className="OrgSeatsField">
      <TextField
        className="activeUsers"
        disabled
        value={activeUsers}
        label="Active Users"
      />
      <p>&nbsp;&nbsp;/&nbsp;&nbsp;</p>
      <TextField
        className="totalSeats"
        disabled={changingSeats}
        error={!!error}
        errorText={error}
        fullWidth={false}
        id={`${id}-seats-field`}
        label="Total Seats"
        min={1}
        onChange={handleChangeLicenses}
        pattern="^\d+"
        step={1}
        type="number"
        value={licenses}
      />
      {isValidNumber && numericLicenses !== seats && !changingSeats && (
        <Button
          flat
          id={`${id}-save-button`}
          onClick={handleSaveClick}
          primary
        >
          Save
        </Button>
      )}
      {changingSeats && <CircularProgress id={`${id}-save-progress`} centered={false} />}
    </div>
  );
});

export default OrgSeatsField;
