import React, { useState } from 'react';
import { DataTable, FontIcon, TableBody, TableColumn, TableHeader, TablePagination, TableRow } from 'react-md';
import { OrganizationOutput } from '@mmc-csm/shared';
import { DateTime } from 'luxon';
import { OrgsState } from '../../store/orgs/orgs-types';
import { OrgStatus } from './OrgStatus';

type OrgsDataTableProps = Pick<OrgsState, 'orgsList'> & {
  id: string,
  onPagination: (start: number, rowsPerPage: number) => void;
  onOrgClicked: (org: OrganizationOutput) => void;
  onHeaderClicked:(column: string) => void;
};

const rowsPerPageItems = [10, 15, 30, 50];

export const OrgsDataTable: React.FC<OrgsDataTableProps> = props => {
  const defaultList = {
    data: undefined,
    total: 0,
    limit: 10,
    offset: 0,
  };
  const { orgsList = defaultList, onPagination, onOrgClicked, onHeaderClicked } = props;
  const orgs = orgsList.data || [];
  const rowsCount = orgsList.total || 0;
  const rowsPerPage = orgsList.limit || defaultList.limit;
  const offset = orgsList.offset || 0;
  const page = offset / rowsPerPage + 1;
  const [sortedColumn, setSortedColumn] = useState('');
  const [direction, setDirection] = useState(true);
  const [columnIndex, setColumnIndex] = useState(0);

  const SortingArrow = ({ direction = true }) => {
    if (direction) return <FontIcon>arrow_drop_up</FontIcon>;
    if (direction === false) return <FontIcon>arrow_drop_down</FontIcon>;
    return <FontIcon />;
  };

  const formatDate = (string = '') => (string != null ? `${`${string.substring(5, 7)}/${string.substring(8, 10)}/${string.substring(0, 4)}`}` : string);
  const expired = (trialExpiresAt = '', plan = '') => (trialExpiresAt != null
    ? Math.floor(
      DateTime.fromISO(trialExpiresAt).startOf('day').diff(
        DateTime.utc().startOf('day'), 'days',
      ).days,
    ) < 0 && plan === 'Free Trial' : false);

  function sortingOrder(columnval:number, column:string) {
    if (column !== sortedColumn || columnIndex === 0) {
      setSortedColumn(column);
      columnval = 0;
    }

    switch (columnval) {
      case 0:
        setColumnIndex(1);
        setDirection(false);
        return column;
      case 1:
        setColumnIndex(2);
        setDirection(true);
        return `-${column}`;
      default:
        setColumnIndex(0);
        setSortedColumn('');
        return '';
    }
  }
  return (
    <DataTable baseId={props.id} plain responsive={false} className="OrgsDataTable">
      <TableHeader>
        <TableRow>
          <TableColumn
            className="ColumnOrgId"
            sorted={sortedColumn === 'id' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'id' ? direction : undefined} />}
            onClick={() => onHeaderClicked(sortingOrder(columnIndex, 'id'))}
          >
              ID
          </TableColumn>
          <TableColumn
            className="ColumnOrgName"
            sorted={sortedColumn === 'name' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'name' ? direction : undefined} />}
            onClick={() => onHeaderClicked(sortingOrder(columnIndex, 'name'))}
          >
              Name
          </TableColumn>
          <TableColumn
            className="ColumnOrgCreatedAt"
            sorted={sortedColumn === 'createdAt' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'createdAt' ? direction : undefined} />}
            onClick={() => onHeaderClicked(sortingOrder(columnIndex, 'createdAt'))}
          >
              Created At
          </TableColumn>
          <TableColumn
            className="ColumnOrgStatus"
            sorted={sortedColumn === 'isActive' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'isActive' ? direction : undefined} />}
            onClick={() => onHeaderClicked(sortingOrder(columnIndex, 'isActive'))}
          >
              Active
          </TableColumn>
          <TableColumn className="ColumnOrgPlan">Plan</TableColumn>
          <TableColumn className="ColumnActiveUsers">Active Users</TableColumn>
          <TableColumn className="ColumnOrgEmail">Email</TableColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {orgs.map(org => (
          <TableRow key={org.id} onClick={() => onOrgClicked(org)}>
            <TableColumn className="ColumnOrgId">{org.id}</TableColumn>
            <TableColumn className="ColumnOrgName">{org.name}</TableColumn>
            <TableColumn className="ColumnOrgCreatedAt">{formatDate(org.createdAt)}</TableColumn>
            <TableColumn>
              <OrgStatus isActive={org.isActive} />
            </TableColumn>
            <TableColumn className="ColumnOrgPlan">{`${org.plan.name}  ${(expired(org.trialExpiresAt, org.plan.name) ? ' (Expired)' : '')}`}</TableColumn>
            <TableColumn className="ColumnActiveUsers">{org.activeUsers}</TableColumn>
            <TableColumn className="ColumnOrgEmail">{org.owner.username}</TableColumn>
          </TableRow>
        ))}
      </TableBody>
      {!rowsCount && (
      <p className='NoResults'>
No Results Found.
        <br />
Clear Filters or Search Again
      </p>
      )}
      <TablePagination
        rows={rowsCount}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageItems={rowsPerPageItems}
        onPagination={onPagination}
      />

    </DataTable>
  );
};
