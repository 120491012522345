import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { authSaga } from './auth/auth-sagas';
import { errorsSaga } from './errors/errors-sagas';
import { orgsSaga } from './orgs/orgs-sagas';
import { plansSaga } from './plans/plans-sagas';
import { skeletonKeysSaga } from './skeleton-keys/skeleton-keys-sagas';
import { iosAppVersionSaga } from './ios-app-version/ios-app-version-sagas';

import { authReducer } from './auth/auth-reducer';
import { orgsReducer } from './orgs/orgs-reducer';
import { orgDetailsReducer } from './orgs/org-details-reducer';
import { plansReducer } from './plans/plans-reducer';
import { skeysReducer } from './skeleton-keys/skeleton-keys-reducer';
import { iosAppVersionReducer } from './ios-app-version/ios-app-version-reducer';
import { toastsReducer } from './toasts/toasts-reducer';

export const createRootReducer = (history: History) =>
  combineReducers({
    auth: authReducer,
    orgs: orgsReducer,
    orgDetails: orgDetailsReducer,
    plans: plansReducer,
    skeletonKeys: skeysReducer,
    iosAppVersion: iosAppVersionReducer,
    toasts: toastsReducer,
    router: connectRouter(history),
  });

export type ApplicationState = ReturnType<ReturnType<typeof createRootReducer>>;

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(errorsSaga),
    fork(orgsSaga),
    fork(plansSaga),
    fork(skeletonKeysSaga),
    fork(iosAppVersionSaga),
  ]);
}
