import { Reducer } from 'redux';
import { PlansActionTypes, PlansState } from './plans-types';

export const plansInitialState: PlansState = {
  loading: false,
  plans: undefined,
};

export const plansReducer: Reducer<PlansState> = (state = plansInitialState, action) => {
  switch (action.type) {
    case PlansActionTypes.LIST: {
      return { ...state, loading: true };
    }
    case PlansActionTypes.LIST_SUCCESS: {
      return { ...state, loading: false, plans: action.payload.plans };
    }
    case PlansActionTypes.LIST_ERROR: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
};
