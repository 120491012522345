import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import { Main } from './Main';
import configureStore from './configure-store';

import './styles/index.scss';

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(<Main store={store} history={history} />, document.getElementById('root'));
