import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { initAuth } from '../store/auth/auth-actions';
import { ApplicationState } from '../store';
import { PageSpinner } from '../components/utils/PageSpinner';

import PrivateRoute from '../components/auth/PrivateRoute';
import SignInPage from './SignInPage';
import { AdminPage } from './AdminPage';
import AuthCallbackPage from './AuthCallbackPage';
import { NotFoundPage } from './NotFoundPage';

type PageContainerProps = {
  initializing: boolean;
  initAuth: typeof initAuth;
};

export class PageContainer extends React.Component<PageContainerProps> {
  componentDidMount() {
    this.props.initAuth();
  }

  render() {
    const { initializing } = this.props;

    if (initializing) {
      return <PageSpinner />;
    }

    return (
      <Switch>
        <Route exact path="/" component={SignInPage} />
        <PrivateRoute path="/adm" component={AdminPage} />
        <Route path="/auth/callback" component={AuthCallbackPage} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
  initializing: auth.initializing,
});

const mapDispatchToProps = {
  initAuth,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageContainer);
