import { AxiosError } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ErrorsActionTypes } from './errors-types';
import { handleError } from './errors-actions';
import { addToast } from '../toasts/toasts-actions';
import { signOut } from '../auth/auth-actions';

function isAxiosError(err: any): err is AxiosError {
  return err && err.isAxiosError;
}

function getRequestErrorMessage(status: number, data: any) {
  switch (status) {
    case 502:
    case 503:
    case 504:
      return `Error trying to access the server. (${status})`;

    case 400:
      return (data && data.message) || 'Invalid request. (400)';

    case 401:
      return 'Session expired. Please sign in again.';

    case 403:
      return 'Access denied.';

    default:
      return `Something went wrong processing the request. (${status})`;
  }
}

function* handleHandleError(action: ReturnType<typeof handleError>) {
  const error = action.payload;

  let { message = 'Ooops. Something went wrong.' } = error;
  console.error('We have an error.', error);

  if (isAxiosError(error) && error.response) {
    const { status, data } = error.response;

    if (status === 401) {
      yield put(signOut());
    }

    message = getRequestErrorMessage(status, data);
  }

  yield put(addToast({ text: message }));
}

export function* errorsSaga() {
  yield takeEvery(ErrorsActionTypes.HANDLE_ERROR, handleHandleError);
}
