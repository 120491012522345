import React from 'react';
import { NavigationDrawer } from 'react-md';
import { NavigationItems } from '../components/navigation/NavigationItems';
import { NavigationContent } from '../components/navigation/NavigationContent';
import SignedUserDropDown from '../components/auth/SignedUserDropDown';

export const AdminPage = () => (
  <NavigationDrawer
    toolbarTitle="Map My Customers - CSM Tool"
    mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY_MINI}
    tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
    desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
    navItems={NavigationItems}
    toolbarActions={<SignedUserDropDown />}
  >
    <div className="AdminPage">
      <NavigationContent />
    </div>
  </NavigationDrawer>
);
