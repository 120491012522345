import { Reducer } from 'redux';
import { SkeletonKeysActionTypes, SkeletonKeysState } from './skeleton-keys-types';

export const skeysInitialState: SkeletonKeysState = {
  dialogVisible: false,
  generating: false,
};

export const skeysReducer: Reducer<SkeletonKeysState> = (state = skeysInitialState, action) => {
  switch (action.type) {
    case SkeletonKeysActionTypes.GENERATE_KEY: {
      return { ...state, generating: true, dialogVisible: true };
    }
    case SkeletonKeysActionTypes.GENERATE_KEY_SUCCESS: {
      return { ...state, generating: false, skeletonKey: action.payload.skeletonKey };
    }
    case SkeletonKeysActionTypes.GENERATE_KEY_ERROR: {
      return { ...state, generating: false, dialogVisible: false };
    }
    case SkeletonKeysActionTypes.CLOSE_DIALOG: {
      return { ...state, dialogVisible: false };
    }
    default: {
      return state;
    }
  }
};
