import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { OrgsActionTypes } from './orgs-types';
import {
  changeAddOnServices,
  changeAddOnServicesError,
  changeAddOnServicesSuccess,
  changeFeature,
  changeFeatureError,
  changeFeatureSuccess,
  changeName,
  changeNameSuccess,
  changeRole,
  changeRoleSuccess,
  changeSeats,
  changeSeatsError,
  changeSeatsSuccess,
  changeSetting,
  changeSettingSuccess,
  changeStatus,
  changeStatusError,
  changeStatusSuccess,
  changeUserAddOnServices,
  changeUserAddOnServicesError,
  changeUserAddOnServicesSuccess,
  changeUserSetting,
  changeUserSettingSuccess,
  extendTrial,
  extendTrialError,
  extendTrialSuccess,
  getOrg,
  getOrgError,
  getOrgSuccess,
  listOrgRoles,
  listOrgRolesError,
  listOrgRolesSuccess,
  listOrgs,
  listOrgsError,
  listOrgSettings,
  listOrgSettingsError,
  listOrgSettingsSuccess,
  listOrgsSuccess,
  listOrgUsers,
  listOrgUsersError,
  listOrgUsersSuccess,
  listUserSettings,
  listUserSettingsError,
  listUserSettingsSuccess,
  resetPasswordCode,
  resetPasswordCodeSuccess,
  selectOrg,
  setStripeId,
  setStripeIdSuccess,
  setSubscriptionId,
  setSubscriptionIdSuccess,
  updateOrg,
  upgradePlan,
  upgradePlanError,
  upgradePlanSuccess,
} from './orgs-actions';
import { OrganizationService } from './orgs-service';
import { handleError } from '../errors/errors-actions';

const service = new OrganizationService();

function* handleListOrgs(action: ReturnType<typeof listOrgs>) {
  try {
    const res = yield call(service.getOrgs, action.payload);
    yield put(listOrgsSuccess(res));
  } catch (err) {
    yield put(listOrgsError());
    yield put(handleError(err));
  }
}

function* handleSelectOrg(action: ReturnType<typeof selectOrg>) {
  yield put(push(`/adm/organizations/${action.payload.org.id}`));
}

function* handleGetOrg(action: ReturnType<typeof getOrg>) {
  try {
    const res = yield call(service.getOrg, action.payload.id);
    yield put(getOrgSuccess(res));
  } catch (err) {
    yield put(getOrgError());
    yield put(handleError(err));
    yield put(push('/adm'));
  }
}

function* handleListOrgUsers(action: ReturnType<typeof listOrgUsers>) {
  try {
    const res = yield call(service.getOrgUsers, action.payload);
    yield put(listOrgUsersSuccess(res));
  } catch (err) {
    yield put(listOrgUsersError());
    yield put(handleError(err));
  }
}
function* handleListOrgRoles(action: ReturnType<typeof listOrgRoles>) {
  try {
    const roles = yield call(service.getOrgRoles, action.payload);
    yield put(listOrgRolesSuccess(roles));
  } catch (err) {
    yield put(listOrgRolesError());
    yield put(handleError(err));
  }
}
function* handleListOrgSettings(action: ReturnType<typeof listOrgSettings>) {
  try {
    const settings = yield call(service.getOrgSettings, action.payload);
    yield put(listOrgSettingsSuccess(settings.data));
  } catch (err) {
    yield put(listOrgSettingsError());
    yield put(handleError(err));
  }
}
function* handleListUserSettings({ payload }: ReturnType<typeof listUserSettings>) {
  try {
    const settings = yield call(service.getUserSettings, payload.orgId, payload.userId);
    yield put(listUserSettingsSuccess(settings.data));
  } catch (err) {
    yield put(listUserSettingsError());
    yield put(handleError(err));
  }
}

function* handleChangeSeats(action: ReturnType<typeof changeSeats>) {
  try {
    const org = yield call(service.changeSeats, action.payload.orgId, action.payload.seats);
    yield put(changeSeatsSuccess(org.userLimit));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(changeSeatsError());
    yield put(handleError(err));
  }
}

function* handleChangeStatus(action: ReturnType<typeof changeStatus>) {
  try {
    const org = yield call(service.changeStatus, action.payload.orgId, action.payload.isActive);
    yield put(changeStatusSuccess(org.isActive));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(changeStatusError());
    yield put(handleError(err));
  }
}

function* handleChangeFeature(action: ReturnType<typeof changeFeature>) {
  try {
    const org = yield call(service.changeMetaData, action.payload.orgId, action.payload.metaData);
    yield put(changeFeatureSuccess(org.metaData));
    yield put(updateOrg(org));
    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  } catch (err) {
    yield put(changeFeatureError());
    yield put(handleError(err));
  }
}

function* handleChangeAddOnServices(action: ReturnType<typeof changeAddOnServices>) {
  try {
    const org = yield call(service.changeAddOnServices, action.payload.orgId, action.payload.addOnServices);
    yield put(changeAddOnServicesSuccess(org.addOnServices));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(changeAddOnServicesError());
    yield put(handleError(err));
  }
}

function* handleChangeUserAddOnServices(action: ReturnType<typeof changeUserAddOnServices>) {
  try {
    const user = yield call(
      service.changeUserAddOnServices,
      action.payload.orgId,
      action.payload.user,
    );
    yield put(changeUserAddOnServicesSuccess(user));
  } catch (err) {
    yield put(changeUserAddOnServicesError());
    yield put(handleError(err));
  }
}

function* handleExtendTrial(action: ReturnType<typeof extendTrial>) {
  try {
    const org = yield call(service.extendTrial, action.payload.orgId, action.payload.trialExpiresAt);
    yield put(extendTrialSuccess(org.trialExpiresAt));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(extendTrialError());
    yield put(handleError(err));
  }
}

function* handleUpgradePlan(action: ReturnType<typeof upgradePlan>) {
  try {
    const org = yield call(service.upgradePlan, action.payload.orgId, action.payload.planId);
    yield put(upgradePlanSuccess(org.plan));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(upgradePlanError());
    yield put(handleError(err));
  }
}

function* handleSetStripeId(action: ReturnType<typeof setStripeId>) {
  try {
    const org = yield call(service.setStripeId, action.payload.orgId, action.payload.stripeId);
    yield put(setStripeIdSuccess(org.stripeId));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* handleChangeName(action: ReturnType<typeof changeName>) {
  try {
    const org = yield call(service.changeName, action.payload.orgId, action.payload.name);
    yield put(changeNameSuccess(org.name));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* handleChangeRole({ payload }: ReturnType<typeof changeRole>) {
  try {
    const org = yield call(service.changeRole, payload.orgId, payload.id, payload.username, payload.role);
    yield put(changeRoleSuccess(org.id));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* handleChangeSetting({ payload }: ReturnType<typeof changeSetting>) {
  try {
    const setting = yield call(service.changeSetting, payload.orgId, payload.setting);
    yield put(changeSettingSuccess(setting));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* handleChangeUserSetting({ payload }: ReturnType<typeof changeUserSetting>) {
  try {
    const setting = yield call(service.changeUserSetting, payload.orgId, payload.userId, payload.setting);
    yield put(changeUserSettingSuccess(setting));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* handleSetSubscriptionId(action: ReturnType<typeof setSubscriptionId>) {
  try {
    const org = yield call(service.setSubscriptionId, action.payload.orgId, action.payload.subscriptionId);
    yield put(setSubscriptionIdSuccess(org.subscriptionId));
    yield put(updateOrg(org));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* handleResetPasswordCode({ payload: { userId, username } }: ReturnType<typeof resetPasswordCode>) {
  try {
    const response = yield call(service.resetPasswordCode, userId, username);
    yield put(resetPasswordCodeSuccess(response.code));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* orgsSaga() {
  yield takeEvery(OrgsActionTypes.LIST, handleListOrgs);
  yield takeEvery(OrgsActionTypes.SELECT, handleSelectOrg);
  yield takeEvery(OrgsActionTypes.GET, handleGetOrg);
  yield takeEvery(OrgsActionTypes.LIST_USERS, handleListOrgUsers);
  yield takeEvery(OrgsActionTypes.CHANGE_SEATS, handleChangeSeats);
  yield takeEvery(OrgsActionTypes.CHANGE_STATUS, handleChangeStatus);
  yield takeEvery(OrgsActionTypes.CHANGE_FEATURE, handleChangeFeature);
  yield takeEvery(OrgsActionTypes.CHANGE_ADD_ON_SERVICES, handleChangeAddOnServices);
  yield takeEvery(OrgsActionTypes.CHANGE_USER_ADD_ON_SERVICES, handleChangeUserAddOnServices);
  yield takeEvery(OrgsActionTypes.EXTEND_TRIAL, handleExtendTrial);
  yield takeEvery(OrgsActionTypes.UPGRADE_PLAN, handleUpgradePlan);
  yield takeEvery(OrgsActionTypes.SET_STRIPE_ID, handleSetStripeId);
  yield takeEvery(OrgsActionTypes.CHANGE_NAME, handleChangeName);
  yield takeEvery(OrgsActionTypes.CHANGE_ROLE, handleChangeRole);
  yield takeEvery(OrgsActionTypes.CHANGE_SETTING, handleChangeSetting);
  yield takeEvery(OrgsActionTypes.CHANGE_USER_SETTING, handleChangeUserSetting);
  yield takeEvery(OrgsActionTypes.SET_SUBSCRIPTION_ID, handleSetSubscriptionId);
  yield takeEvery(OrgsActionTypes.LIST_ROLES, handleListOrgRoles);
  yield takeEvery(OrgsActionTypes.LIST_SETTINGS, handleListOrgSettings);
  yield takeEvery(OrgsActionTypes.LIST_USER_SETTINGS, handleListUserSettings);
  yield takeEvery(OrgsActionTypes.RESET_PASSWORD_CODE, handleResetPasswordCode);
}
