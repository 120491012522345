import React from 'react';
import { connect } from 'react-redux';
import { OrganizationOutput } from '@mmc-csm/shared';

import { OrgsState } from '../../store/orgs/orgs-types';
import { listOrgs, selectOrg } from '../../store/orgs/orgs-actions';
import { ApplicationState } from '../../store';
import { OrgsDataTable } from './OrgsDataTable';
import { DataTableSpinner } from '../utils/DataTableSpinner';
import { OrgsSearchBar } from './OrgsSearchBar';
import FilterDrawer from './OrgFilters';

interface PropsFromDispatch {
  listOrgs: typeof listOrgs;
  selectOrg: typeof selectOrg;
}
let orderVar = '';
type OrgsListProps = Omit<OrgsState, 'selectedOrg' | 'loadingSelectedOrg'> & PropsFromDispatch;

export class OrgsList extends React.Component<OrgsListProps> {
  public componentDidMount() {
    if (!this.props.orgsList) {
      this.props.listOrgs({ order: orderVar, limit: this.props.limit, offset: 0, search: this.props.search, filter: this.props.filter });
    }
  }

  handlePagination = (start: number, rowsPerPage: number) => {
    this.props.listOrgs({ order: orderVar, limit: rowsPerPage, offset: start, search: this.props.search, filter: this.props.filter });
    window.scrollTo(0, 0);
  };

  handleOrgClicked = (org: OrganizationOutput) => {
    this.props.selectOrg(org);
  };

  handleSearch = (search: string) => {
    const searchString = search ? `{"$or":[{"name":{"$in":"${search}"}},{"username":{"$in":"${search}"}}]}` : undefined;
    this.props.listOrgs({ limit: this.props.limit, offset: 0, search: searchString, filter: this.props.filter });
  };

  getFilters = (filter: object) => {
    let isActiveFilter;
    const planParams = [];
    let freeTrialParams;
    let active = false;
    let inactive = false;
    let freeTrialExpired = false;
    let freeTrialActive = false;
    const today = new Date();
    for (const [key, value] of Object.entries(filter)) {
      if (value) {
        switch (key) {
          case 'active':
            active = true;
            break;
          case 'inactive':
            inactive = true;
            break;
          case 'free':
            freeTrialActive = true;
            break;
          case 'freeExpired':
            freeTrialExpired = true;
            break;
          case 'starter':
            planParams.push('1,2');
            break;
          case 'professional':
            planParams.push('3,4');
            break;
          case 'enterprise':
            planParams.push('5,6');
            break;
          case 'monthly':
            planParams.push('13');
            break;
          case 'annualSmall':
            planParams.push('14');
            break;
          case 'annualMedium':
            planParams.push('15');
            break;
          case 'annualLarge':
            planParams.push('16');
            break;
        }
      }
    }
    if (!active && inactive) { isActiveFilter = '{"isActive":{"$ne":true}}'; }
    if (active && !inactive) { isActiveFilter = '{"isActive":{"$eq":true}}'; }

    if (freeTrialActive && freeTrialExpired) { planParams.push('7'); } else if (freeTrialActive && !freeTrialExpired) { freeTrialParams = (`{"$and":[{"planId":{"$eq":7}},{"trialExpiresAt":{"$gt":"${today.toISOString()}"}}]}`); } else if (!freeTrialActive && freeTrialExpired) { freeTrialParams = (`{"$and":[{"planId":{"$eq":7}},{"trialExpiresAt":{"$lte":"${today.toISOString()}"}}]}`); }

    const planFilter = `{"planId":{"$in":[${planParams.toString()}]}}`;

    // set up filterString, add the isActive filter
    let filterString = isActiveFilter || '';
    // add a comma if there is a parameter before and after
    filterString = (filterString && freeTrialParams) ? filterString.concat(',') : filterString;
    // need to add an $or operator if freeTrialParams exists, add freeTrialParams
    filterString = freeTrialParams ? filterString.concat(`{"$or":[${freeTrialParams}`) : filterString;
    // add a comma if there is a parameter before and after
    filterString = (filterString && planFilter !== '{"planId":{"$in":[]}}') ? filterString.concat(',') : filterString;
    // add the other plan parameters
    filterString = planFilter !== '{"planId":{"$in":[]}}' ? filterString.concat(planFilter) : filterString;
    // add ending to $or operator if freeTrialParams exists
    filterString = freeTrialParams ? filterString.concat(']}') : filterString;
    return filterString;
  };

  handleFilter = (filter: object) => {
    const filterString = this.getFilters(filter);
    this.props.listOrgs({ limit: this.props.limit, offset: 0, search: this.props.search, filter: filterString, order: orderVar });
  };

  handleHeader = (order: string) => {
    orderVar = order;
    this.props.listOrgs({ order, limit: this.props.limit, offset: 0, search: this.props.search, filter: this.props.filter });
  };

  public render() {
    const { loading, orgsList } = this.props;

    return (
      <>
        <div className="OrgsSearchBar">
          <FilterDrawer onFilter={this.handleFilter} />
          <OrgsSearchBar defaultValue={this.props.search} onSearch={this.handleSearch} />
        </div>
        <DataTableSpinner id="orgs-dt-spinner" loading={loading}>
          <OrgsDataTable
            id="orgs-dt-table"
            orgsList={orgsList}
            onPagination={this.handlePagination}
            onOrgClicked={this.handleOrgClicked}
            onHeaderClicked={this.handleHeader}
          />
        </DataTableSpinner>
      </>
    );
  }
}

const mapStateToProps = ({ orgs }: ApplicationState) => ({
  limit: orgs.limit,
  offset: orgs.offset,
  search: orgs.search,
  loading: orgs.loading,
  orgsList: orgs.orgsList,
});

const mapDispatchToProps = {
  listOrgs,
  selectOrg,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgsList);
