import jwtdecode from 'jwt-decode';
import { IUser } from '@mmc-csm/shared';
import { GoogleAuthService } from './google-auth-service';
import { authTokenService } from './auth-token-service';

class AuthService {
  private readonly googleService: GoogleAuthService;

  constructor() {
    this.googleService = new GoogleAuthService();
  }

  async init() {
    return this.googleService.init();
  }

  signIn() {
    this.googleService.redirectToAuthPage();
  }

  async signOut() {
    authTokenService.removeToken();
    window.location.reload();
  }

  async isSignedIn() {
    const accessToken = authTokenService.getToken();

    if (!accessToken) {
      this.signIn();
    }

    const user = this.getUserFromToken(accessToken!);
    const expires = new Date(0);
    expires.setUTCSeconds(user.exp);
    const signedIn = user && expires.valueOf() > new Date().valueOf();

    return { signedIn, user };
  }

  private getUserFromToken(accessToken: string): IUser {
    return jwtdecode<IUser>(accessToken);
  }
}

export const authService = new AuthService();
