import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { checkSignedIn } from '../../store/auth/auth-actions';
import { ApplicationState } from '../../store';
import { PageSpinner } from '../utils/PageSpinner';

type AllProps = RouteProps & {
  isSignedIn?: boolean;
  checkSignedIn: typeof checkSignedIn;
};

export class PrivateRoute extends React.Component<AllProps> {
  componentDidMount() {
    if (this.props.isSignedIn === undefined) {
      this.props.checkSignedIn();
    }
  }

  render() {
    const { isSignedIn } = this.props;

    if (isSignedIn === undefined) {
      return <PageSpinner />;
    }

    const { component, render, children, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          if (!isSignedIn) {
            return <Redirect to="/" />;
          }

          if (component) {
            return React.createElement(component, props);
          }

          if (render) {
            return render(props);
          }

          if (children && React.Children.count(children)) {
            return children;
          }

          return null;
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
  isSignedIn: auth.isSignedIn,
});

const mapDispatchToProps = {
  checkSignedIn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);
