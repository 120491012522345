export enum ToastsActionTypes {
  ADD_TOAST = '@@toasts/ADD_TOAST',
  REMOVE_TOAST = '@@toasts/REMOVE_TOAST',
}

export interface ToastsState {
  readonly toasts: Toast[];
}

export interface Toast {
  text: string;
}
