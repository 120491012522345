import React from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress, DialogContainer, SelectionControlGroup } from 'react-md';
import { PlanOutput } from '@mmc-csm/shared';
import { PlansState } from '../../store/plans/plans-types';
import { ApplicationState } from '../../store';
import { listPlans } from '../../store/plans/plans-actions';
import { getPlanInfo } from '../../store/plans/plans-helpers';

interface OrgUpgradePlanProps extends PlansState {
  upgradingPlan: boolean;
  onPlanChanged: (planId: number) => void;
  listPlans: typeof listPlans;
  currentPlan: PlanOutput;
}

interface OrgUpgradePlanState {
  dialogVisible: boolean;
  selectedPlanId?: number;
}

export class OrgUpgradePlan extends React.Component<OrgUpgradePlanProps, OrgUpgradePlanState> {
  constructor(props: OrgUpgradePlanProps) {
    super(props);

    this.state = {
      dialogVisible: false,
    };
  }

  componentDidMount() {
    if (!this.props.plans) {
      this.props.listPlans();
    }
  }

  handleOpenDialog = () => {
    this.setState({ dialogVisible: true, selectedPlanId: undefined });
  };

  handleCancelDialog = () => {
    this.setState({ dialogVisible: false });
  };

  handleConfirmDialog = () => {
    if (this.state.selectedPlanId) {
      this.setState({ dialogVisible: false });
      this.props.onPlanChanged(this.state.selectedPlanId);
    }
  };

  handlePlanSelection = (value: any) => {
    this.setState({ selectedPlanId: parseInt(value, 10) });
  };

  render() {
    const { loading, plans: allPlans = [] } = this.props;
    const { upgradingPlan, currentPlan } = this.props;
    const { dialogVisible, selectedPlanId } = this.state;
    const currentPlanName = getPlanInfo(currentPlan).name;

    const plans = allPlans.map(p => getPlanInfo(p))
      .filter(p => (p.metaData != null || /Enterprise|Hold Plan/.test(p.name)) && (p.planType === 'license'));
    console.log('all plans=', allPlans, allPlans.map(p => getPlanInfo(p)), ' available plans=', plans);

    const actions = [
      { children: 'Cancel', onClick: this.handleCancelDialog },
      { children: 'Upgrade', primary: true, onClick: this.handleConfirmDialog, disabled: !this.state.selectedPlanId },
    ];

    return (
      <>
        <Button primary flat disabled={upgradingPlan} onClick={this.handleOpenDialog} className="UpgradePlanButton">
          {upgradingPlan ? 'Saving...' : 'Upgrade'}
        </Button>
        <DialogContainer
          id="upgrade-plan-dialog"
          title="Select the new plan"
          actions={actions}
          onHide={this.handleCancelDialog}
          visible={dialogVisible}
        >
          {loading && <CircularProgress id="loading-plans-pgr" />}

          {!loading && !plans.length && <span>{`There are no plans above ${currentPlanName} to upgrade to.`}</span>}

          {!loading && !!plans.length && (
            <SelectionControlGroup
              id="plans-radio-group"
              name="plans-radio-group"
              type="radio"
              value={selectedPlanId}
              defaultValue="none"
              onChange={this.handlePlanSelection}
              controls={plans.map(p => ({ label: p.name, value: p.id }))}
            />
          )}
        </DialogContainer>
      </>
    );
  }
}

const mapStateToProps = ({ plans }: ApplicationState) => ({
  ...plans,
});

const mapDispatchToProps = {
  listPlans,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgUpgradePlan);
