import { call, put, takeEvery } from 'redux-saga/effects';
import { IOSAppVersionActionTypes } from './ios-app-version-types';
import { handleError } from '../errors/errors-actions';
import { API } from '../../utils/api';
import {
  getVersionError,
  getVersionSuccess,
  saveVersion,
  saveVersionError,
  saveVersionSuccess,
} from './ios-app-version-actions';

function* handleGetVersion() {
  try {
    const res = yield call(API.get.bind(API), '/settings/ios-app-version-under-review');
    yield put(getVersionSuccess(res.version));
  } catch (err) {
    yield put(getVersionError());
    yield put(handleError(err));
  }
}

function* handleSaveVersion(action: ReturnType<typeof saveVersion>) {
  try {
    const { version } = action.payload;
    const res = yield call(API.post.bind(API), '/settings/ios-app-version-under-review', { version });
    yield put(saveVersionSuccess(res.version));
  } catch (err) {
    yield put(saveVersionError());
    yield put(handleError(err));
  }
}

export function* iosAppVersionSaga() {
  yield takeEvery(IOSAppVersionActionTypes.GET_VERSION, handleGetVersion);
  yield takeEvery(IOSAppVersionActionTypes.SAVE_VERSION, handleSaveVersion);
}
