import { action } from 'typesafe-actions';
import { AuthActionTypes, IsSignedInResponse } from './auth-types';

export const initAuth = () => action(AuthActionTypes.INIT_AUTH);
export const initAuthSuccess = () => action(AuthActionTypes.INIT_AUTH_SUCCESS);
export const initAuthError = () => action(AuthActionTypes.INIT_AUTH_ERROR);

export const signIn = () => action(AuthActionTypes.SIGN_IN);
export const signInError = () => action(AuthActionTypes.SIGN_IN_ERROR);

export const signOut = () => action(AuthActionTypes.SIGN_OUT);
export const signOutSuccess = () => action(AuthActionTypes.SIGN_OUT_SUCCESS);

export const checkSignedIn = () => action(AuthActionTypes.CHECK_SIGNED_IN);
export const checkSignedInSuccess = (response: IsSignedInResponse) =>
  action(AuthActionTypes.CHECK_SIGNED_IN_SUCCESS, response);
export const checkSignedInError = () => action(AuthActionTypes.CHECK_SIGNED_IN_ERROR);

export const oauthCallback = () => action(AuthActionTypes.OAUTH_CALLBACK);
export const oauthCallbackError = () => action(AuthActionTypes.OAUTH_CALLBACK_ERROR);
