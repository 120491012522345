import { action } from 'typesafe-actions';
import { IOSAppVersionActionTypes } from './ios-app-version-types';

export const getVersion = () => action(IOSAppVersionActionTypes.GET_VERSION);

export const getVersionSuccess = (version: string) => action(IOSAppVersionActionTypes.GET_VERSION_SUCCESS, { version });

export const getVersionError = () => action(IOSAppVersionActionTypes.GET_VERSION_ERROR);

export const changeVersion = () => action(IOSAppVersionActionTypes.CHANGE_VERSION);

export const editVersion = (version: string) => action(IOSAppVersionActionTypes.EDIT_VERSION, { version });

export const saveVersion = (version?: string) => action(IOSAppVersionActionTypes.SAVE_VERSION, { version });

export const saveVersionSuccess = (version: string) =>
  action(IOSAppVersionActionTypes.SAVE_VERSION_SUCCESS, { version });

export const saveVersionError = () => action(IOSAppVersionActionTypes.SAVE_VERSION_ERROR);

export const closeDialog = () => action(IOSAppVersionActionTypes.CLOSE_DIALOG);
