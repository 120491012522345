import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';
import { ApplicationState, createRootReducer, rootSaga } from './store';
import { orgsInitialState } from './store/orgs/orgs-reducer';
import { orgDetailsInitialState } from './store/orgs/org-details-reducer';
import { authInitialState } from './store/auth/auth-reducer';
import { toastsInitialState } from './store/toasts/toasts-reducer';
import { skeysInitialState } from './store/skeleton-keys/skeleton-keys-reducer';
import { iosAppVersionInitialState } from './store/ios-app-version/ios-app-version-reducer';
import { plansInitialState } from './store/plans/plans-reducer';

const initialState = {
  auth: authInitialState,
  orgs: orgsInitialState,
  orgDetails: orgDetailsInitialState,
  plans: plansInitialState,
  skeletonKeys: skeysInitialState,
  iosAppVersion: iosAppVersionInitialState,
  toasts: toastsInitialState,
};

export default function configureStore(history: History): Store<ApplicationState> {
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
