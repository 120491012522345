import React from 'react';
import SkeletonKeys from '../skeleton-keys/SkeletonKeys';
import IOSAppVersionUnderReview from '../ios-app-version/IOSAppVersionUnderReview';

export const AdminTools = () => (
  <div className="AdminTools">
    <SkeletonKeys />
    <IOSAppVersionUnderReview />
  </div>
);
