import { IUser } from '@mmc-csm/shared/src/auth/user.interface';
import { authTokenService } from './auth-token-service';

export class GoogleAuthService {
  private user: IUser | null = null;

  async init() {
    const jwt = authTokenService.getToken();
    if (!jwt) return this.redirectToAuthPage();
    return undefined;
  }

  async signIn() {
    this.redirectToAuthPage();
  }

  redirectToAuthPage() {
    window.location.href = '/api/auth/google';
  }
}
