import React from 'react';
import { Button, Cell, Grid } from 'react-md';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { checkSignedIn, signIn } from '../store/auth/auth-actions';
import { ApplicationState } from '../store';
import { AuthState } from '../store/auth/auth-types';
import { PageSpinner } from '../components/utils/PageSpinner';

import logo from '../assets/logo.png';

type SignInPageProps = AuthState & {
  signIn: typeof signIn;
  checkSignedIn: typeof checkSignedIn;
};

export class SignInPage extends React.Component<SignInPageProps> {
  componentDidMount() {
    if (this.props.isSignedIn === undefined) {
      this.props.checkSignedIn();
    }
  }

  render() {
    const { isSignedIn } = this.props;

    if (isSignedIn) {
      return <Redirect to="/adm" />;
    }

    if (isSignedIn === undefined) {
      return <PageSpinner />;
    }

    return (
      <Grid className="SignInPage">
        <Cell size={12} align="middle" className="SignInPageWrapper">
          <div>
            <div>
              <img src={logo} alt="Map My Customers" className="SignInLogo" />
            </div>
            <h1 className="SignInTitle">CSM Tool</h1>
            <Button raised primary iconClassName="fab fa-google" className="SignInButton" onClick={this.props.signIn}>
              Sign in with Google
            </Button>
          </div>
        </Cell>
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
  isSignedIn: auth.isSignedIn,
});

const mapDispatchToProps = {
  signIn,
  checkSignedIn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInPage);
