import { call, put, takeEvery } from 'redux-saga/effects';

import { AuthActionTypes } from './auth-types';
import {
  checkSignedInError,
  checkSignedInSuccess,
  initAuthError,
  initAuthSuccess,
  signInError,
  signOutSuccess,
} from './auth-actions';
import { authService } from './auth-service';
import { handleError } from '../errors/errors-actions';

function* handleInitAuth() {
  try {
    yield call(authService.init.bind(authService));
    yield put(initAuthSuccess());
  } catch (err) {
    yield put(initAuthError());
    yield put(handleError(err));
  }
}

function* handleSignIn() {
  try {
    yield call(authService.signIn.bind(authService));
  } catch (err) {
    yield signInError();
    yield put(handleError(err));
  }
}

function* handleSignOut() {
  yield call(authService.signOut.bind(authService));
  yield put(signOutSuccess());
}

function* handleCheckSignedIn() {
  try {
    const signedIn = yield call(authService.isSignedIn.bind(authService));
    yield put(checkSignedInSuccess(signedIn));
  } catch (err) {
    yield put(checkSignedInError());
    yield put(handleError(err));
  }
}

export function* authSaga() {
  yield takeEvery(AuthActionTypes.INIT_AUTH, handleInitAuth);
  yield takeEvery(AuthActionTypes.SIGN_IN, handleSignIn);
  yield takeEvery(AuthActionTypes.SIGN_OUT, handleSignOut);
  yield takeEvery(AuthActionTypes.CHECK_SIGNED_IN, handleCheckSignedIn);
}
