import { call, put, takeEvery } from 'redux-saga/effects';
import { SkeletonKeysActionTypes } from './skeleton-keys-types';
import { generateKeyError, generateKeySuccess } from './skeleton-keys-actions';
import { handleError } from '../errors/errors-actions';
import { API } from '../../utils/api';

function* handleGenerateKey() {
  try {
    const res = yield call(API.get.bind(API), '/skeleton-keys');
    yield put(generateKeySuccess(res.skeletonKey));
  } catch (err) {
    yield put(generateKeyError());
    yield put(handleError(err));
  }
}

export function* skeletonKeysSaga() {
  yield takeEvery(SkeletonKeysActionTypes.GENERATE_KEY, handleGenerateKey);
}
