import axios, { Method } from 'axios';
import { config } from '../config';
import { authTokenService } from '../store/auth/auth-token-service';

class ApiService {
  async get<T>(path: string) {
    return this.sendRequest<T>('get', path);
  }

  async post<T>(path: string, data: any) {
    return this.sendRequest<T>('post', path, data);
  }

  async put<T>(path: string, data: any) {
    return this.sendRequest<T>('put', path, data);
  }

  private async sendRequest<T extends any>(method: Method, path: string, data?: any): Promise<T> {
    const authHeader = this.getAuthHeader();

    const result = await axios.request<T>({
      method,
      data,
      baseURL: config.apiBaseURL,
      url: path,
      headers: {
        ...authHeader,
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  }

  private getAuthHeader() {
    const token = authTokenService.getToken();

    if (!token) {
      return {};
    }

    return { Authorization: `Bearer ${token}` };
  }
}

export const API = new ApiService();
