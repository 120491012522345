import OrgsList from '../orgs/OrgsList';
import { AdminTools } from '../tools/AdminTools';

export const routes = [
  {
    label: 'Organizations',
    icon: 'account_balance',
    path: '/adm',
    exact: true,
    component: OrgsList,
  },
  {
    label: 'Admin Tools',
    icon: 'build',
    path: '/adm/tools',
    component: AdminTools,
  },
];
