import React from 'react';
import { Snackbar as ReactMdSnackbar } from 'react-md';
import { connect } from 'react-redux';
import { Toast } from '../../store/toasts/toasts-types';
import { ApplicationState } from '../../store';
import { removeToast } from '../../store/toasts/toasts-actions';

interface SnackbarProps {
  toasts: Toast[];
  dismissToast: () => void;
}

export const Snackbar: React.FC<SnackbarProps> = ({ toasts, dismissToast }) => (
  <ReactMdSnackbar id="app-snackbar" className="Snackbar" toasts={toasts} autohide onDismiss={dismissToast} />
);

const mapStateToProps = ({ toasts }: ApplicationState) => ({
  ...toasts,
});

const mapDispatchToProps = {
  dismissToast: removeToast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Snackbar);
