import { call, put, takeEvery } from 'redux-saga/effects';
import { PlansActionTypes } from './plans-types';
import { listPlansError, listPlansSuccess } from './plans-actions';
import { handleError } from '../errors/errors-actions';
import { API } from '../../utils/api';

function* handleListPlans() {
  try {
    const res = yield call(API.get.bind(API), '/plans');
    yield put(listPlansSuccess(res));
  } catch (err) {
    yield put(listPlansError());
    yield put(handleError(err));
  }
}

export function* plansSaga() {
  yield takeEvery(PlansActionTypes.LIST, handleListPlans);
}
