import { PlanOutput } from '@mmc-csm/shared';

export enum PlansActionTypes {
  LIST = '@@plans/LIST',
  LIST_SUCCESS = '@@plans/LIST_SUCCESS',
  LIST_ERROR = '@@plans/LIST_ERROR',
}

export interface PlansState {
  readonly loading: boolean;
  readonly plans?: PlanOutput[];
}
