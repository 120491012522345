import { IUser } from '@mmc-csm/shared';

export enum AuthActionTypes {
  INIT_AUTH = '@@auth/INIT_AUTH',
  INIT_AUTH_SUCCESS = '@@auth/INIT_AUTH_SUCCESS',
  INIT_AUTH_ERROR = '@@auth/INIT_AUTH_ERROR',

  SIGN_IN = '@@auth/SIGN_IN',
  SIGN_IN_ERROR = '@@auth/SIGN_IN_ERROR',

  SIGN_OUT = '@@auth/SIGN_OUT',
  SIGN_OUT_SUCCESS = '@@auth/SIGN_OUT_SUCCESS',

  CHECK_SIGNED_IN = '@@auth/IS_SIGNED_IN',
  CHECK_SIGNED_IN_SUCCESS = '@@auth/IS_SIGNED_IN_SUCCESS',
  CHECK_SIGNED_IN_ERROR = '@@auth/IS_SIGNED_IN_ERROR',

  OAUTH_CALLBACK = '@@auth/OAUTH_CALLBACK',
  OAUTH_CALLBACK_SUCCESS = '@@auth/OAUTH_CALLBACK_SUCCESS',
  OAUTH_CALLBACK_ERROR = '@@auth/OAUTH_CALLBACK_ERROR',
}

export interface AuthState {
  readonly initializing: boolean;
  readonly isSignedIn?: boolean;
  readonly user?: IUser;
}

export interface IsSignedInResponse {
  isSignedIn: boolean;
  user?: IUser;
}
