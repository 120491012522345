import React, { useState } from 'react';
import { Button } from 'react-md';
import { OrgStatus } from './OrgStatus';
import { ConfirmationDialog } from '../utils/ConfirmationDialog';

interface OrgStatusFieldProps {
  isActive: boolean;
  changingStatus: boolean;
  onStatusChanged: (newStatus: boolean) => void;
}

export const OrgStatusField: React.FC<OrgStatusFieldProps> = props => {
  const { isActive, changingStatus, onStatusChanged } = props;
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);

  const actionText = isActive ? 'Deactivate' : 'Activate';
  const buttonText = changingStatus ? 'Saving...' : actionText;

  const handleAccountStatusChange = () => {
    setConfirmDialogVisible(true);
  };

  const handleConfirmDialog = () => {
    setConfirmDialogVisible(false);
    onStatusChanged(!isActive);
  };

  const handleCancelDialog = () => {
    setConfirmDialogVisible(false);
  };

  return (
    <div className="OrgsStatusField">
      <OrgStatus isActive={isActive} />
      <Button primary flat disabled={changingStatus} onClick={handleAccountStatusChange}>
        {buttonText}
      </Button>
      <ConfirmationDialog
        title={`${actionText} account?`}
        message={`Are you sure you want to ${actionText.toLowerCase()} this account?`}
        confirmButtonText={buttonText}
        visible={confirmDialogVisible}
        onConfirm={handleConfirmDialog}
        onCancel={handleCancelDialog}
      />
    </div>
  );
};
