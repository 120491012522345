import { Reducer } from 'redux';
import { OrgsActionTypes, OrgsState } from './orgs-types';

export const orgsInitialState: OrgsState = {
  orgsList: undefined,
  limit: 10,
  offset: 0,
  search: undefined,
  loading: false,
  passwordCodeGenerating: false,
};

export const orgsReducer: Reducer<OrgsState> = (state = orgsInitialState, action) => {
  switch (action.type) {
    case OrgsActionTypes.LIST: {
      const { limit, offset, search } = action.payload;
      return { ...state, loading: true, limit, offset, search };
    }
    case OrgsActionTypes.LIST_SUCCESS: {
      return { ...state, loading: false, orgsList: action.payload };
    }
    case OrgsActionTypes.LIST_ERROR: {
      return { ...state, loading: false };
    }
    case OrgsActionTypes.RESET_PASSWORD_CODE: {
      return { ...state, resetPasswordCode: undefined, passwordCodeGenerating: true };
    }
    case OrgsActionTypes.RESET_PASSWORD_CODE_SUCCESS: {
      const { code } = action.payload;
      return { ...state, resetPasswordCode: code, passwordCodeGenerating: false };
    }
    case OrgsActionTypes.UPDATED: {
      const { orgsList } = state;

      if (!orgsList) return state;

      const data = orgsList.data.map(org => {
        if (org.id === action.payload.org.id) {
          return { ...org, ...action.payload.org };
        }
        return org;
      });

      return { ...state, orgsList: { ...orgsList, data } };
    }
    default: {
      return state;
    }
  }
};
