import qs from 'qs';
import {
  OrganizationListInput,
  OrganizationListOutput,
  OrganizationOutput,
  OrganizationUser,
  OrganizationUserListInput,
  OrganizationUserListOutput, Setting, SettingListOutput,
} from '@mmc-csm/shared';
import { API } from '../../utils/api';

export class OrganizationService {
  async getOrgs(input?: OrganizationListInput): Promise<OrganizationListOutput> {
    const query = qs.stringify(input);

    return API.get<OrganizationListOutput>(`/organizations?${query}`);
  }

  async getOrg(id: number): Promise<OrganizationOutput> {
    return API.get<OrganizationOutput>(`/organizations/${id}`);
  }

  async getOrgUsers(input: OrganizationUserListInput): Promise<OrganizationUserListOutput> {
    const query = qs.stringify(input, { filter: (p, v) => (p === 'id' ? undefined : v) });

    return API.get<OrganizationUserListOutput>(`/organizations/${input.id}/users?${query}`);
  }

  async getOrgRoles(id: OrganizationOutput['id']): Promise<OrganizationUserListOutput> {
    return API.get<OrganizationUserListOutput>(`/organizations/${id}/roles`);
  }

  async getOrgSettings(orgId: OrganizationOutput['id']): Promise<SettingListOutput> {
    return API.get<SettingListOutput>(`/organizations/${orgId}/settings`);
  }

  async getUserSettings(orgId: OrganizationOutput['id'], userId: OrganizationUser['id']): Promise<SettingListOutput> {
    return API.get<SettingListOutput>(`/organizations/${orgId}/users/${userId}/settings`);
  }

  async changeSeats(orgId: number, seats: number) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/change-user-limit`, { userLimit: seats });
  }

  async changeStatus(orgId: number, isActive: boolean) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/change-status`, { isActive });
  }

  async changeMetaData(orgId: number, metaData: OrganizationOutput['metaData']) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/change-metadata`, { metaData });
  }

  async changeAddOnServices(orgId: number, addOnServices: OrganizationOutput['addOnServices']) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/change-addonservices`, { addOnServices });
  }

  async changeUserAddOnServices(orgId: number, user: OrganizationUser) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/users/${user.id}/change-addonservices`,
      { user });
  }

  async changeName(orgId: number, name: string) {
    return API.put<{ name: string }>(`/organizations/${orgId}/change-name`, { name });
  }

  async changeRole(orgId: number, id: number, username: string, role: OrganizationUser['role']) {
    return API.put<{ roleId: number }>(`/organizations/${orgId}/users/${id}/change-role`, { id, username, role });
  }

  async changeSetting(orgId: OrganizationOutput['id'], setting: Setting) {
    return API.put<Setting>(`/organizations/${orgId}/change-setting`, { setting });
  }

  async changeUserSetting(orgId: OrganizationOutput['id'], userId: OrganizationUser['id'], setting: Setting) {
    return API.put<Setting>(`/organizations/${orgId}/users/${userId}/change-setting`, { setting });
  }

  async extendTrial(orgId: number, trialExpiresAt: string) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/extend-trial`, { trialExpiresAt });
  }

  async upgradePlan(orgId: number, planId: number) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/upgrade-plan`, { planId });
  }

  async setStripeId(orgId: number, stripeId: string) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/upgrade-stripe-id`, { stripeId });
  }

  async setSubscriptionId(orgId: number, subscriptionId: string) {
    return API.put<{ status: boolean }>(`/organizations/${orgId}/upgrade-subscription-id`, { subscriptionId });
  }

  async resetPasswordCode(id: number, requestingUser: string) {
    return API.post<{ code: string }>('/auth/resetPasswordCode', { id, requestingUser });
  }
}
