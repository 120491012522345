import React from 'react';
import { DateTime } from 'luxon';

interface OrgTrialIndicatorProps {
  trialExpiresAt: string;
  className?: string;
}

export const OrgTrialIndicator: React.FC<OrgTrialIndicatorProps> = ({ trialExpiresAt, className }) => {
  const expiresAt = DateTime.fromISO(trialExpiresAt).startOf('day');
  const today = DateTime.utc().startOf('day');
  const diff = Math.floor(expiresAt.diff(today, 'days').days);
  const expired = diff < 0;

  const spanClassName = `OrgTrialIndicator OrgTrialIndicator--${expired ? 'expired' : 'active'} ${className}`;
  const status = expired ? 'Expired' : `${diff} days`;
  const dateString = expiresAt.toFormat('MMM dd');
  const tooltip = expired ? 'The trial is expired.' : `The trial will expire in ${diff} days, on ${dateString}.`;

  return (
    <span className={spanClassName} title={tooltip}>
      {status}
    </span>
  );
};
