import { PlanOutput } from '@mmc-csm/shared';

export function getPlanInfo(plan: PlanOutput) {
  if (plan.trialing) {
    return { ...plan, trial: true, isOldPlan: true };
  }

  let planType = '';

  switch (plan.interval) {
    case 1:
      planType = '(Monthly)';
      break;
    case 12:
      planType = '(Annual)';
      break;
    default:
      planType = '';
      break;
  }

  return { ...plan, name: `${plan.name} ${planType}`, trial: false, isOldPlan: !plan.metaData };
}
