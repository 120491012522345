import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import OrgsDetailsForm from '../orgs/OrgDetails';

export const NavigationContent = () => (
  <Switch>
    <Route path="/adm/organizations/:id">
      <h2 className="NavigationTitle">Organization Details</h2>
      <OrgsDetailsForm />
    </Route>
    {routes.map(route => (
      <Route key={route.path} path={route.path} exact={route.exact}>
        <h2 className="NavigationTitle">{route.label}</h2>
        <route.component />
      </Route>
    ))}
  </Switch>
);
