import React, { memo, ReactText, useCallback, useState } from 'react';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields/TextField';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';

interface Props {
  readonly changingLimit: boolean;
  readonly id: string;
  readonly limit: number;
  readonly onChangeLimit: (newLimit: number) => void,
}

const LeadFinderLimitField = memo<Props>(({ changingLimit, id, limit, onChangeLimit }) => {
  const [leadFinderLimit, setLeadFinderLimit] = useState<ReactText>(limit);
  const [error, setError] = useState<string | undefined>();

  const handleChangeLimit = useCallback(
    (value: ReactText) => {
      const parsedValue = parseInt(`${value}`.trim(), 10);
      if (!Number.isNaN(parsedValue) && parsedValue > 0) {
        setError(undefined);
      } else if (Number.isNaN(parsedValue) && `${value}`.trim() !== '') {
        setError('Please enter a number');
      } else if (parsedValue < 0) {
        setError('Limit must be positive, enter 0 for no limit');
      }
      setLeadFinderLimit(Number.isNaN(parsedValue) ? '' : parsedValue);
    },
    [setError, setLeadFinderLimit],
  );

  const numericLicenses = parseInt(`${leadFinderLimit}`.trim(), 10);
  const isValidNumber = Number.isInteger(numericLicenses) && numericLicenses >= 0;
  const handleSaveClick = useCallback(() => onChangeLimit(numericLicenses), [numericLicenses, onChangeLimit]);

  return (
    <div className="LeadFinderLimitField">
      <TextField
        className="LeadFinderLimitField_limit"
        disabled={changingLimit}
        error={!!error}
        errorText={error}
        fullWidth={false}
        id={`${id}-limit-field`}
        label="Lead Finder Monthly Search Limit"
        min={0}
        onChange={handleChangeLimit}
        pattern="^\d+"
        step={1}
        type="number"
        value={leadFinderLimit}
      />
      {isValidNumber && !changingLimit && (
        <Button
          flat
          id={`${id}-save-button`}
          onClick={handleSaveClick}
          primary
        >
          Save
        </Button>
      )}
      {changingLimit && <CircularProgress id={`${id}-save-progress`} centered={false} />}
    </div>
  );
});

export default LeadFinderLimitField;
