import React from 'react';
import { connect } from 'react-redux';
import { DialogContainer, FontIcon, TextField } from 'react-md';
import { AdminToolsItem } from '../tools/AdminToolsItem';
import { ApplicationState } from '../../store';
import { IOSAppVersionState } from '../../store/ios-app-version/ios-app-version-types';
import {
  changeVersion,
  closeDialog,
  editVersion,
  getVersion,
  saveVersion,
} from '../../store/ios-app-version/ios-app-version-actions';

type IOSAppVersionUnderReviewProps = IOSAppVersionState & {
  getVersion: typeof getVersion;
  changeVersion: typeof changeVersion;
  editVersion: typeof editVersion;
  saveVersion: typeof saveVersion;
  closeDialog: typeof closeDialog;
};

export class IOSAppVersionUnderReview extends React.Component<IOSAppVersionUnderReviewProps> {
  componentDidMount() {
    if (!this.props.version) {
      this.props.getVersion();
    }
  }

  handleEditVersion = (value: any) => {
    this.props.editVersion(value);
  };

  handleSave = () => {
    this.props.saveVersion(this.props.editedVersion);
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13) {
      this.handleSave();
    }
  };

  render() {
    const actions = [
      { children: 'Close', onClick: this.props.closeDialog },
      { children: 'Save', primary: true, onClick: this.handleSave },
    ];

    const { loading, saving, version, editedVersion } = this.props;

    let buttonText = 'Change';
    let value = version || 'None';

    if (loading) {
      value = '';
      buttonText = 'Loading';
    }

    if (saving) {
      value = editedVersion || 'None';
      buttonText = 'Saving';
    }

    return (
      <>
        <AdminToolsItem
          icon={<FontIcon iconClassName="fab fa-apple" />}
          title="iOS app version under Apple review"
          description="The iOS app will use this setting to hide the sign-up button during the Apple review process."
          buttonText={buttonText}
          value={value}
          loading={loading || saving}
          onButtonClick={this.props.changeVersion}
        />
        <DialogContainer
          id="ios-app-version-dialog"
          visible={this.props.dialogVisible}
          onHide={this.props.closeDialog}
          actions={actions}
          width={400}
          title="iOS app version under review"
        >
          <TextField
            id="ios-app-version"
            label="Version"
            value={this.props.editedVersion}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleEditVersion}
          />
        </DialogContainer>
      </>
    );
  }
}

const mapStateToProps = ({ iosAppVersion }: ApplicationState) => ({
  ...iosAppVersion,
});

const mapDispatchToProps = {
  getVersion,
  changeVersion,
  editVersion,
  saveVersion,
  closeDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IOSAppVersionUnderReview);
