const AUTH_TOKEN_KEY = 'userJWT';

export const authTokenService = {
  getToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  },
  removeToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  },
};
