import React, { useState } from 'react';
import { Button, DialogContainer, TextField } from 'react-md';

interface OrgExtendTrialProps {
  extendingTrial: boolean;
  onTrialExtended: (days: number) => void;
}

export const OrgExtendTrial: React.FC<OrgExtendTrialProps> = props => {
  const { extendingTrial, onTrialExtended } = props;
  const [dialogVisible, setDialogVisible] = useState(false);
  const [fieldValue, setFieldValue] = useState('14');

  const isFieldValueValid = () => /^\d{1,2}$/.test(fieldValue) && +fieldValue !== 0;

  const handleOpenDialog = () => {
    setDialogVisible(true);
  };

  const handleCancelDialog = () => {
    setDialogVisible(false);
  };

  const handleConfirmDialog = () => {
    if (isFieldValueValid()) {
      setDialogVisible(false);
      onTrialExtended(parseInt(fieldValue, 10));
    }
  };

  const actions = [
    { children: 'Cancel', onClick: handleCancelDialog },
    { children: 'Extend Trial', primary: true, onClick: handleConfirmDialog, disabled: !isFieldValueValid() },
  ];

  return (
    <>
      <Button primary flat disabled={extendingTrial} onClick={handleOpenDialog} className="ExtendTrialButton">
        {extendingTrial ? 'Saving...' : 'Extend Trial'}
      </Button>
      <DialogContainer
        id="extend-trial-dialog"
        title="Extend trial"
        actions={actions}
        onHide={handleCancelDialog}
        visible={dialogVisible}
      >
        <TextField
          id="txt-trial-days"
          label="Days"
          type="number"
          min={1}
          max={99}
          required
          pattern="/^\d{1,2}$/"
          error={!isFieldValueValid()}
          value={fieldValue}
          onChange={(value: any) => setFieldValue(value)}
        />
      </DialogContainer>
    </>
  );
};
