import React, { ReactText } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { Button, Cell, CircularProgress, Grid, TextField } from 'react-md';
import { DateTime } from 'luxon';

import { OrganizationOutput } from '@mmc-csm/shared';
import { ApplicationState } from '../../store';
import { OrgDetailsState } from '../../store/orgs/orgs-types';
import {
  changeAddOnServices,
  changeFeature,
  changeName,
  changeRole,
  changeSeats, changeSetting,
  changeStatus,
  extendTrial,
  getOrg,
  listOrgRoles, listOrgSettings,
  listOrgUsers,
  setStripeId,
  setSubscriptionId,
  upgradePlan,
} from '../../store/orgs/orgs-actions';
import { getPlanInfo } from '../../store/plans/plans-helpers';
import { DataTableSpinner } from '../utils/DataTableSpinner';
import { OrgTrialIndicator } from './OrgTrialIndicator';
import OrgUsersDataTable from './OrgUsersDataTable';
import { OrgStatusField } from './OrgStatusField';
import { OrgExtendTrial } from './OrgExtendTrial';
import OrgUpgradePlan from './OrgUpgradePlan';
import OrgSeatsField from './OrgSeatsField';
import { OrgUsersSearchBar } from './OrgUsersSearchBar';
import OrgFeatures from './OrgFeatures';
import LeadFinderLimitField from './LeadFinderLimitField';


interface PropsFromActions {
  getOrg: typeof getOrg;
  listOrgUsers: typeof listOrgUsers;
  listOrgRoles: typeof listOrgRoles;
  listOrgSettings: typeof listOrgSettings;
  changeSeats: typeof changeSeats;
  changeStatus: typeof changeStatus;
  changeName: typeof changeName;
  changeRole: typeof changeRole;
  changeFeature: typeof changeFeature;
  changeSetting: typeof changeSetting;
  changeAddOnServices: typeof changeAddOnServices;
  extendTrial: typeof extendTrial;
  upgradePlan: typeof upgradePlan;
  setStripeId: typeof setStripeId;
  setSubscriptionId: typeof setSubscriptionId;
  push: typeof push;
}

type RouteProps = RouteComponentProps<{ id: string }>;
type OrgDetailsProps = PropsFromActions & OrgDetailsState & RouteProps;
interface OrgsDetailsState {
  stripeIdStated?: string;
  nameStated?: string;
  subscriptionIdStated?: string
}

export class OrgsDetails extends React.Component<OrgDetailsProps, OrgsDetailsState> {
  constructor(props: OrgDetailsProps) {
    super(props);

    this.state = {
      stripeIdStated: '',
      nameStated: '',
      subscriptionIdStated: '',
    };
  }

  public componentDidMount() {
    const id = parseInt(this.props.match.params.id, 10);

    if (!Number.isInteger(id)) {
      this.props.push('/adm');
      return;
    }

    if (!this.props.org || this.props.org.id !== id) {
      this.props.getOrg(id);
    }

    this.props.listOrgUsers({
      id,
      limit: this.props.usersLimit,
      offset: 0,
      search: this.props.search
        ? `{"$or":[{"username":{"$in":"${this.props.search}"}},{"fullName":{"$in":"${this.props.search}"}}]}`
        : '',
    });
    this.props.listOrgRoles(id);
    this.props.listOrgSettings(id);
  }

  componentWillReceiveProps(nextProps: Readonly<OrgDetailsProps>) {
    if (nextProps.org) {
      const stateToUpdate: OrgsDetailsState = {};

      if (nextProps.org!.stripeId! !== this.state.stripeIdStated) {
        stateToUpdate.stripeIdStated = nextProps.org!.stripeId;
      }
      if (nextProps.org!.name! !== this.state.nameStated) {
        stateToUpdate.nameStated = nextProps.org!.name;
      }
      if (nextProps.org!.subscriptionId !== this.state.subscriptionIdStated) {
        stateToUpdate.subscriptionIdStated = nextProps.org!.subscriptionId;
      }

      if (Object.keys(stateToUpdate).length) {
        this.setState(stateToUpdate);
      }
    }
  }

  setStripeId = (stripeIdStated: ReactText) => this.setState({ stripeIdStated: stripeIdStated as string });

  changeName = (nameStated: ReactText) => this.setState({ nameStated: nameStated as string });

  clearStripeId = () => this.setState({ stripeIdStated: this.props.org!.stripeId });

  saveStripeId = () => this.props.setStripeId(this.props.org!.id, this.state.stripeIdStated!);

  clearName = () => this.setState({ nameStated: this.props.org!.name });

  saveName = () => this.props.changeName(this.props.org!.id, this.state.nameStated!);

  setSubscriptionId = (subscriptionIdStated: ReactText) => this.setState({ subscriptionIdStated: subscriptionIdStated as string });

  clearSubscriptionId = () => this.setState({ subscriptionIdStated: this.props.org!.subscriptionId });

  saveSubscriptionId = () => this.props.setSubscriptionId(this.props.org!.id, this.state.subscriptionIdStated!);

  handleUsersPagination = (start: number, rowsPerPage: number) => {
    const { id } = this.props.org!;
    this.props.listOrgUsers({ id, limit: rowsPerPage, offset: start, search: this.props.search ? `{"$or":[{"username":{"$in":"${this.props.search}"}},{"fullName":{"$in":"${this.props.search}"}}]}` : undefined });
  };

  handleChangeSeats = (newSeats: number) => {
    this.props.changeSeats(this.props.org!.id, newSeats);
  };

  handleChangeLeadFinderLimit = (newLimit: number) => {
    const { id, addOnServices } = this.props.org!;
    this.props.changeAddOnServices(id, { ...(addOnServices || {}), orgLimit: newLimit });
  };

  handleChangeStatus = (newStatus: boolean) => {
    this.props.changeStatus(this.props.org!.id, newStatus);
  };

  handleChangeName = (newName: string) => {
    this.props.changeName(this.props.org!.id, newName);
  };

  handleChangeRole = (id: number, username: string, roleId: number) => {
    this.props.changeRole(this.props.org!.id, id, username, { id: roleId });
  };

  handleChangeOrg = (features: OrganizationOutput['metaData']['features'], updatedAddOnServices: { demographicData: boolean, dupeCheck: boolean, emailService: boolean, hipaaEnabled: boolean, leadGen: string, territories: boolean }) => {
    const { id, metaData = {}, addOnServices } = this.props.org!;
    this.props.changeFeature(id, { ...metaData, features }, () => {
      this.props.changeAddOnServices(id, { ...(addOnServices || {}), ...updatedAddOnServices });
    });
  };

  handleTrialExtended = (days: number) => {
    const today = DateTime.utc();
    const currentExpiryDate = DateTime.fromISO(this.props.org!.trialExpiresAt).toUTC();
    const newExpiryDate = DateTime.max(today, currentExpiryDate).plus({ days });

    this.props.extendTrial(this.props.org!.id, newExpiryDate.toISO());
  };

  handlePlanChanged = (planId: number) => {
    this.props.upgradePlan(this.props.org!.id, planId);
  };

  handleSearch = (search: string) => {
    this.props.listOrgUsers({ id: this.props.org!.id, limit: this.props.usersLimit, offset: 0, search: `{"$or":[{"username":{"$in":"${search}"}},{"fullName":{"$in":"${search}"}}]}` });
  };

  public render() {
    if (this.props.loadingOrg || !this.props.org) {
      return <CircularProgress id="loading-org-prg" />;
    }

    const { id, name, plan, trialExpiresAt, isActive, userLimit, stripeId } = this.props.org;
    const {
      loadingUsers,
      usersList,
      changingSeats,
      changingSetting,
      changingStatus,
      rolesList,
      extendingTrial,
      upgradingPlan,
    } = this.props;

    const { stripeIdStated, nameStated } = this.state;

    const planInfo = getPlanInfo(plan);
    const leadFinderLimit = this.props.org.addOnServices.orgLimit ? this.props.org.addOnServices.orgLimit : 0;

    return (
      <div className="OrgDetails">
        <section>
          <Grid>
            <Cell size={3}>
              <TextField
                id="org-details-name"
                className="OrgName"
                label="Name"
                inputStyle={{ fontSize: 22 }}
                value={nameStated}
                onChange={this.changeName}
              />
            </Cell>

            {name !== nameStated ? (
              <Cell size={2}>
                <Button primary flat onClick={this.saveName}>
                  Save
                </Button>
                <Button flat onClick={this.clearName}>
                  Cancel
                </Button>
              </Cell>
            ) : <Cell size={2} />}

            <Cell offset={5} size={2}>
              <OrgFeatures
                features={this.props.org!.metaData.features}
                addOnServices={this.props.org!.addOnServices}
                onChangeOrg={this.handleChangeOrg}
              />
            </Cell>
          </Grid>
          <Grid>
            <Cell size={5}>
              <OrgStatusField
                isActive={isActive}
                changingStatus={changingStatus}
                onStatusChanged={this.handleChangeStatus}
              />
            </Cell>
          </Grid>
          <Grid>
            <Cell size={2}>
              <TextField id="org-details-id" className="OrgID" label="Organization ID" disabled defaultValue={id} />
            </Cell>
            <Cell size={3} />
            <Cell size={5}>
              <div className="OrgsPlanField">
                <TextField
                  id="org-plan-name"
                  label="Plan"
                  disabled
                  defaultValue={planInfo.name}
                  inlineIndicator={planInfo.trial ? <OrgTrialIndicator trialExpiresAt={trialExpiresAt} /> : undefined}
                />
                {planInfo.trial && (
                  <OrgExtendTrial extendingTrial={extendingTrial} onTrialExtended={this.handleTrialExtended} />
                )}
                <OrgUpgradePlan
                  currentPlan={plan}
                  upgradingPlan={upgradingPlan}
                  onPlanChanged={this.handlePlanChanged}
                />
              </div>
            </Cell>
          </Grid>
          <Grid>

            <Cell size={5}>
              <TextField id="org-details-stripeId" className="OrgID" label="Stripe ID" value={stripeIdStated} onChange={this.setStripeId} />
            </Cell>
            {stripeId !== stripeIdStated && (
              <Cell size={5}>
                <Button primary flat onClick={this.saveStripeId}>
                  Save
                </Button>
                <Button flat onClick={this.clearStripeId}>
                  Cancel
                </Button>
              </Cell>
            )}

            <Cell size={2}>
              <OrgSeatsField
                changingSeats={changingSeats}
                id="org-change-seats"
                onChangeSeats={this.handleChangeSeats}
                activeUsers={usersList ? usersList.count : 0}
                seats={userLimit}
              />
            </Cell>

            <Cell size={4} offset={1}>
              <LeadFinderLimitField
                changingLimit={changingSetting}
                id="org-change-lead-finder-limit"
                limit={leadFinderLimit}
                onChangeLimit={this.handleChangeLeadFinderLimit}
              />
            </Cell>
          </Grid>
          <Grid>
            <Cell size={3} />
          </Grid>
          <Grid>
            <Cell size={3} />
          </Grid>
          <Grid>
            <Cell size={3} />
          </Grid>

        </section>
        <section>
          <div>
            <OrgUsersSearchBar defaultValue={this.props.search} onSearch={this.handleSearch} />
          </div>
          <DataTableSpinner id="org-users-dt-spinner" loading={loadingUsers}>
            <OrgUsersDataTable
              id="org-users-dt-table"
              onPagination={this.handleUsersPagination}
              onChangeRole={this.handleChangeRole}
              orgId={this.props.org.id}
              roles={rolesList ? rolesList.data : []}
              usersList={usersList}
            />
          </DataTableSpinner>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ orgDetails }: ApplicationState) => ({
  ...orgDetails,
});

const mapDispatchToProps = {
  getOrg,
  push,
  listOrgUsers,
  listOrgRoles,
  listOrgSettings,
  changeSeats,
  changeStatus,
  changeName,
  changeFeature,
  changeAddOnServices,
  extendTrial,
  upgradePlan,
  setStripeId,
  setSubscriptionId,
  changeRole,
  changeSetting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OrgsDetails));
