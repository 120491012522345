import React, { useState } from 'react';
import { Button, FontIcon, TextField } from 'react-md';

interface OrgSearchProps {
  defaultValue?: string;
  onSearch: (search: string) => void;
}

export const OrgUsersSearchBar: React.FC<OrgSearchProps> = props => {
  const [buttonState, setButtonState] = useState(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      props.onSearch((e.currentTarget as HTMLInputElement).value);
      setButtonState(true);
    }
  };

  const handleClick = () => {
    const fieldInput = document.getElementById('orgs-search') as HTMLInputElement | null;

    if (!buttonState && fieldInput) {
      props.onSearch(fieldInput.value);
      setButtonState(true);
    } else {
      (document.getElementById('orgs-search') as HTMLInputElement).value = '';
      props.onSearch('');
      setButtonState(false);
    }
  };
  const buttonIcon = () => {
    if (buttonState) return <FontIcon>close</FontIcon>;
    return <FontIcon>search</FontIcon>;
  };

  return (

    <div className="OrgSearchBar">
      <div>
        <h3 className="OrgDetailsSubtitle">Users</h3>
      </div>
      <div />
      <div className="OrgSearchInput">
        <TextField
          id="orgs-search"
          defaultValue={props.defaultValue}
          inlineIndicator={<Button onClick={handleClick}>{buttonIcon()}</Button>}
          onKeyDown={handleKeyDown}
          fullWidth
          placeholder="Search by Name or Email"
        />
      </div>
    </div>
  );
};
