import React from 'react';
import { Avatar, Button } from 'react-md';

interface AdminToolsItemProps {
  icon: React.ReactElement;
  title: string;
  description?: string;
  value?: string;
  buttonText: string;
  loading?: boolean;
  onButtonClick?: () => void;
}

export const AdminToolsItem: React.FC<AdminToolsItemProps> = props => (
  <div className="AdminToolsItem">
    <div className="AdminToolsItemIcon">
      <Avatar icon={props.icon} />
    </div>
    <div className="AdminToolsItemContent">
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </div>
    <div className="AdminToolsItemValue">
      <span>{props.value}</span>
    </div>
    <div className="AdminToolsItemButton">
      <Button primary raised onClick={props.onButtonClick} disabled={props.loading}>
        {props.buttonText}
      </Button>
    </div>
  </div>
);
