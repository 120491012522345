import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';
import { History } from 'history';

import { ApplicationState } from './store';
import PageContainer from './pages/PageContainer';
import Snackbar from './components/utils/Snackbar';

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

export const Main: React.FC<MainProps> = ({ store, history }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PageContainer />
      <Snackbar />
    </ConnectedRouter>
  </Provider>
);
