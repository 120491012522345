import { Reducer } from 'redux';
import { AuthActionTypes, AuthState } from './auth-types';

export const authInitialState: AuthState = {
  initializing: true,
  isSignedIn: undefined,
  user: undefined,
};

export const authReducer: Reducer<AuthState> = (state = authInitialState, action) => {
  switch (action.type) {
    case AuthActionTypes.INIT_AUTH: {
      return { ...state, initializing: true };
    }
    case AuthActionTypes.INIT_AUTH_SUCCESS: {
      return { ...state, initializing: false };
    }
    case AuthActionTypes.INIT_AUTH_ERROR: {
      return { ...state, initializing: false, errors: action.payload };
    }
    case AuthActionTypes.CHECK_SIGNED_IN_SUCCESS: {
      return { ...state, isSignedIn: action.payload.signedIn, user: action.payload.user };
    }
    case AuthActionTypes.CHECK_SIGNED_IN_ERROR: {
      return { ...state, isSignedIn: false };
    }
    case AuthActionTypes.SIGN_OUT_SUCCESS: {
      return { ...state, isSignedIn: false, user: undefined };
    }
    case AuthActionTypes.OAUTH_CALLBACK_SUCCESS: {
      return { ...state, isSignedIn: action.payload.signedIn, user: action.payload.user };
    }
    case AuthActionTypes.OAUTH_CALLBACK_ERROR: {
      return { ...state, isSignedIn: false, user: undefined };
    }
    default: {
      return state;
    }
  }
};
